const moment = require('moment');

const token = $('meta[name=outer-token]').attr('content');

var socket = io(window.location.hostname + ':3000', {
    query: {
        token: token
    }
});


let reversed = false;

$(document).ready(function () {
    $('.stream-watch-stream').on('click', function (e) {
        e.preventDefault();

        let html = '';

        $('.stream-list > *').each(function () {

            html = this.outerHTML + html;
        });

        $('.stream-list').html(html);

        reversed = !reversed;
    });
});


socket.on('connect', function () {

    socket.on('block', function (data) {
        $(".block-session").css('display', 'block');
        $("body").css('overflow', 'hidden');
    });

    socket.on('notification', function (data) {
        const notification = data.notification;

        if (typeof backendBag === "object" && notification.data.stream) {
            if (backendBag.stream_id == notification.data.stream.id) {
                return;
            }
        }

        if (confirm(notification.message) && notification.link) {
            window.location.href = notification.link;
        }
    });

    if (typeof backendBag === "object" && typeof backendBag.stream_id !== "undefined") {

        updatePostList();

        socket.on('deleteStream', function (data) {
            $(".stream-container").html('<div class="stream-list-deleted">Broadcast has been deleted</div>');
        });

        socket.on('post', function (data) {
            playSound();
            updatePostList();
        });

        socket.on('deletePost', function (data) {
            updatePostList();
        });

        socket.on('streamStarted', function () {
            updatePostList();
            playSound();
        });

        socket.emit('joinStream', {
            stream_id: backendBag.stream_id
        });
    }
});

function updatePostList() {
    $.ajax({
        url: backendBag.text_stream_show_ajax,
        success: function (res) {
            let html = '';

            res = JSON.parse(res);

            res.posts.forEach(function (post) {
                let image = '';

                if (post.image) {
                    image = `<div class="stream-text-border__img" style="background-image: url(${post.image})"></div>`
                }

                const postHtml = `
                    <a>
                        <div class="stream-text-border">
                        
                            ${image}
                            <div class="stream-text-border__info">
                                <span class="date time-from">` + moment(post.createdAt, 'YYYY-MM-DD HH:mm:ss').locale($('html').attr('lang')).calendar() + `</span>
                            </div>
                            <p>
                                ${post.body}
                            </p>
                        </div>
                    </a>`;

                if (reversed) {
                    html = postHtml + html;
                } else {
                    html += postHtml;
                }

            });

            $('.stream-list').html(html);
        }
    })
}

function playSound() {
    const filename = '/bundles/argentumadmin/audio/notification';
    const mp3Source = '<source src="' + filename + '.mp3" type="audio/mpeg">';
    const oggSource = '<source src="' + filename + '.ogg" type="audio/ogg">';
    const embedSource = '<embed hidden="true" autostart="true" loop="false" src="' + filename + '.mp3">';
    $("#sound").html('<audio autoplay="autoplay">' + mp3Source + oggSource + embedSource + '</audio>');
}