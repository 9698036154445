const $ = require('jquery');
global.$ = global.jQuery = $;

require('@fancyapps/fancybox/dist/jquery.fancybox.css');
require('@fancyapps/fancybox/dist/jquery.fancybox');
require('flexslider');
require('jquery-mask-plugin');
require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui/ui/i18n/datepicker-uk');
require('./poll');
const moment = require('moment');


$(document).ready(function () {
    $(function () {
        $('body').addClass('ready');
    });

    // $('ul.sf-menu').superfish();

    $(function () {
        //caches a jQuery object containing the header element
        var header = $("body");
        num = $('#mbr_body').offset().top;
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();

            if (scroll >= num) {
                header.addClass("fixed");
            } else {
                header.removeClass("fixed");
            }
        });
    });

    // hide #back-top first
    $("#back-top").hide();

    // fade in #back-top
    $(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('#back-top').fadeIn();
            } else {
                $('#back-top').fadeOut();
            }
        });

        // scroll body to 0px on click
        $('#back-top a').click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 800);
            return false;
        });
    });
});

$(document).ready(function () {
    $.datepicker.setDefaults($.datepicker.regional['uk']);

    $("#calendar-block").datepicker({
        language: 'uk',
        firstDay: 1, // Start with Monday
        onSelect: function (date) {
            var dat = date.split('.');
            var url = window.location.href;
            var url_parts = url.split('?');
            var qs = url_parts.length > 1 ? url_parts[1] : '';

            var target = '/' + lang + '/news/';

            if (target) {
                target += dat[2] + '-' + dat[1] + '-' + dat[0];
            }

            if (target && (target != url)) {
                if (window.location.href.indexOf("map") > -1) {
                    var url = window.location.href.split("?")[0];
                    window.location.href = url + target;
                } else {
                    window.location.href = target;
                }
            }

            return false;
        }
    });
});

var fancyOpts = {
    'transitionIn': 'elastic',
    'transitionOut': 'elastic',
    'padding': 0,
    'wrapCSS': 'b-custom-popup',
    'speedIn': 500,
    'speedOut': 300,
    'fitToView': false,
    'aspectRatio': true,
    'autoScale': false,
    'maxWidth': "100%",
    'maxHeight': "100%",
    'minHeight': "0",
    'autoSize': true,
    'closeBtn': true,
    'scrolling': 'no'
}

$(document).ready(function () {
    if (!/scps=1/.test(document.cookie) && ($(window).width() > 800)) {
        $("#info-msg").show();
        var date = new Date(new Date().getTime() + 1 * 6 * 60 * 60 * 1000);
        document.cookie = "scps=1; path=/; expires=" + date.toUTCString();
    }

    window.CloseSetCookie = function () {
        $('#info-msg').hide();
        var date = new Date(new Date().getTime() + 1 * 6 * 60 * 60 * 1000);
        document.cookie = "scps=1; path=/; expires=" + date.toUTCString();
    }
});

$(document).ready(function () {
    $('.subscription_expiration_date').each(function () {
        $(this).text(moment($(this).text().trim(), 'YYYY-MM-DD HH:mm:ss').locale($('html').attr('lang')).fromNow());
    });

    $('.time-from').each(function () {
        $(this).text(moment($(this).text().trim(), 'YYYY-MM-DD HH:mm:ss').locale($('html').attr('lang')).calendar());
    });

    //TODO: Widget popup


    $('.chart ul li img').on('click', function (e) {
        e.preventDefault();
        $('.chart-popup__content img').attr('src', $(this).attr('data-image'));
        $('.chart-popup').addClass('show');
    });

    $('.chart-popup__close').on('click', function (e) {
        e.preventDefault();
        $('.chart-popup').removeClass('show');
        $('.chart-popup__content img').attr('src', $(this).attr('data-image', ''));
    });
    $('.chart-popup__content img').on('click', function () {
        if ($(window).width() > 768) {
            $('.chart-popup').removeClass('show');
            $('.chart-popup__content img').attr('src', $(this).attr('data-image', ''));
        }
    });
    $(window).on('scroll', function () {
        if ($(window).width() > 768) {
            $('.chart-popup').removeClass('show');
            $('.chart-popup__content img').attr('src', $(this).attr('data-image', ''));
        }
    });


    $('.b-news-text iframe').parent('p').addClass('iframeWrapper');


    $('.blog-authors-mob-btn').on('click', function () {
        $('.blog-authors-mob').toggleClass('active');
        $('.blog-authors-mob-select').slideToggle();
    })

    if($('div').hasClass('selectChecked')){
        $('.selectChecked label').appendTo('.selectChecked');
    }

});
