$(document).ready(function () {
    const title = $('.poll-result-title').val();

    $('.poll-form').on('submit', function (e) {
        e.preventDefault();

        const form = $(e.target);

        const data = {
            voting_id: form.find('input[name=voting_id]').val(),
            options: []
        };

        let optionsTitle = {};

        form.find('input[type=checkbox]').each(function () {
            const option = $(this);

            const title = option.parent().parent().find('.poll__option-btn').text();

            optionsTitle[option.attr('data-id')] = option.attr('data-admin') === 'admin' ? {
                admin: true,
                text: title.substr(0, title.lastIndexOf('('))
            } : {text: title};

            if (option.is(':checked')) {
                data.options.push(option.attr('data-id'));
            }
        });

        if (data.options.length === 0) {
            return;
        }

        $.post(form.attr('data-url'), data).done(function (res) {
            if (!res.voted) {
                return;
            }

            form.find('.poll__item').remove();

            let options = '';

            Object.values(res.result.options).forEach((option) => {
                const active = data.options.includes(option.option.id.toString()) ? 'active' : '';

                let optionTitle = optionsTitle[[option.option.id.toString()]];

                if (optionTitle.admin === true) {
                    optionTitle.text += ` (${option.total})`
                }

                options += `
                
                <div class="poll-result-data ">
                    <div class="poll-result-percentage">${option.percentage}
                        <span>%</span></div>
                    <div class="poll-result-block">
                        <span class="poll-result__answer ${active}">
                            ${optionTitle.text}
                        </span>
                        <div style="width: ${option.percentage}%"
                             class="poll-result-line  ${active}"></div>
                    </div>
                </div>
            `
            });


            let pollResult = `
                <div class="poll-result">
                    <div class="poll__item poll-result__item">
                        <h4 class="poll__headline poll-result__headline">${title}</h4>
                        ${options}
                    </div>
                </div>
            `;

            form.append(pollResult);

        }).fail(function (err) {
            console.log(err);

        });

    });
    $(".poll__option").on("click", function () {
        if ($(".switch input").is(":checked")) {
            $(this).addClass("checked")
        } else {
            $(this).removeClass("checked")

        }

    })
});